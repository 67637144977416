<template>
    <BT-Blade-Item
        bladeName="stock-consignment"
        :bladesData="bladesData"
        canDelete
        :canSave="isExternalTransfer != null"
        :changeToggle="changeToggle"
        navigation="stock-consignments"
        title="Stock Consignment"
        :loadingMsg="loadingMsg"
        :onCanDelete="item => item != null && item.isTransfer && item.buyerID != null && item.buyerID == item.sellerID"
        :onNew="newConsignment"
        :onPullSuccessAsync="pullConsignment"
        :refreshToggle="refreshToggle"
        :saveToggle="saveToggle"
        :defaultBladeWidth="550"
        @sourceChanged="updateBlade">
        <template v-slot="{ item, data }">
            <v-row v-if="isExternalTransfer == null && data.isNew" no-gutters class="mt-4">
                <v-col cols="6">
                    <v-btn @click="isExternalTransfer = false" height="75" width="100%">
                        Internal
                    </v-btn>
                </v-col>
                <v-col cols="6">
                    <v-btn @click="isExternalTransfer = true" height="75" width="100%">
                        3PL
                    </v-btn>
                </v-col>
            </v-row>
            <v-row v-else no-gutters>
                <v-col v-if="!data.isNew" cols="12">
                    <BT-Field-Company
                        :id="item.buyerID"
                        label="Receiver"
                        showAction
                        :title="item.buyer.companyName">
                        <template v-slot:action>
                            <BT-Menu
                                hideHeader
                                icon="mdi-dots-horizontal">
                                <template v-slot>
                                    <v-list-item @click="forceResolve(item)">
                                        <v-list-item-content>
                                            <v-list-item-title>Force Resolve</v-list-item-title>
                                        </v-list-item-content>
                                    </v-list-item>
                                    <v-list-item @click.stop="updateWeightsAndPackageCount(item)">
                                        <v-list-item-content>
                                            <v-list-item-title>Update Weight</v-list-item-title>
                                        </v-list-item-content>
                                    </v-list-item>
                                    <v-list-item>
                                        <v-list-item-content>
                                            <v-list-item-subtitle>Created on {{ item.createdOn | toLongDateAndTime }}</v-list-item-subtitle>
                                        </v-list-item-content>
                                    </v-list-item>
                                </template>
                            </BT-Menu>
                        </template>
                    </BT-Field-Company>
                </v-col>

                <v-col v-if="data.isNew" cols="12" sm="6">
                    <BT-Select-List-Box
                        isEditing
                        isSelecting
                        label="Departure"
                        navigation="locations"
                        textFilter="toFamiliarLocationLine"
                        v-model="item.departureLocationID">
                        <template v-slot="{ item }">
                            <v-list-item-content>
                                <v-list-item-title>{{ item.locationName }}</v-list-item-title>
                                <v-list-item-subtitle>{{ item | toLocationLine }}</v-list-item-subtitle>
                            </v-list-item-content>
                        </template>
                    </BT-Select-List-Box>
                </v-col>
                <v-col v-else cols="6" sm="4" class="d-flex align-center">
                    <BT-Btn
                        v-if="!item.isTransfer && item.departureLocationID == null"
                        height="auto"
                        inline
                        leftIcon="mdi-map-marker-check"
                        label="Select FROM"
                        @click="selectSupplyLocation(item)" />
                    <BT-Field-Address v-else :value="item.departureLocation">
                        <template v-slot:label>
                            <v-list-item-subtitle>
                                <v-row no-gutters>
                                    <BT-Btn
                                        v-if="!item.isTransfer && item.departureLocationID != null && item.performedOn == null"
                                        buttonClass="error--text"
                                        @click="cancelConsignment(item)"
                                        confirm
                                        leftIcon="mdi-close"
                                        inline
                                        small />
                                    <span class="align-self-center">FROM</span>
                                    <v-spacer />
                                    
                                </v-row>
                            </v-list-item-subtitle>
                        </template>
                    </BT-Field-Address>
                </v-col>

                <v-col v-if="data.isNew" cols="12" sm="6">
                    <BT-Select-List-Box
                        v-if="isExternalTransfer"
                        customURL="/getall/CourierLocations"
                        v-model="item.destinationLocationID"
                        label="Destination"
                        isEditing
                        isSelecting
                        navigation="couriers"
                        textFilter="toFamiliarLocationLine">
                        <template v-slot="{ item }">
                            <v-list-item-avatar>
                                <v-img :src="companyLogoURL(item.companyAccountID)" class="mx-auto">
                                    <template v-slot:placeholder>
                                        <v-icon color="primary">mdi-account</v-icon>
                                    </template>
                                </v-img>
                            </v-list-item-avatar>
                            <v-list-item-content>
                                <v-list-item-title>{{ item | toLocationLine }}</v-list-item-title>
                                <v-list-item-subtitle>{{ item.companyAccount.companyName }}</v-list-item-subtitle>
                            </v-list-item-content>
                        </template>
                    </BT-Select-List-Box>
                    <BT-Select-List-Box
                        v-else
                        v-model="item.destinationLocationID"
                        label="Destination"
                        isEditing
                        isSelecting
                        navigation="locations"
                        textFilter="toFamiliarLocationLine">
                        <template v-slot="{ item }">
                            <v-list-item-content v-if="item != null">
                                <v-list-item-title>{{ item.locationName }}</v-list-item-title>
                                <v-list-item-subtitle>{{ item | toLocationLine }}</v-list-item-subtitle>
                            </v-list-item-content>
                        </template>
                    </BT-Select-List-Box>
                </v-col>
                <v-col v-else cols="6" sm="4">
                    <BT-Field-Address
                        label="TO"
                        :value="item.destinationLocation" />
                </v-col>
                
                <v-col cols="6" sm="4">
                    <BT-Field-String
                        v-if="!data.isNew"
                        v-model="item.consignmentNumber"
                        :isEditing="data.isEditing"
                        label="Consignment #" />
                </v-col>

                <v-col cols="12">
                    <BT-Field-Date
                        v-model="item.dueDate"
                        label="Due On"
                        :isEditing="data.isNew" />
                </v-col>

                <v-col v-if="!data.isNew" cols="12" sm="4">
                    <v-divider />
                    <v-subheader>Provision Status</v-subheader>

                    <v-slide-x-transition hide-on-leave>
                        <BT-Btn
                            v-if="!item.isFullyProvisioned"
                            class="primary ma-1"
                            icon
                            label="Provision"
                            leftIcon="mdi-cube-outline"
                            :to="{ name: 'picking-hub', params: { consignmentID: item.id }}" />
                        <v-card v-else flat class="success ma-1">
                            <v-card-text>
                                <v-icon small left>mdi-check</v-icon>
                                Provisioned
                            </v-card-text>
                        </v-card>
                    </v-slide-x-transition>
                </v-col>

                <v-col v-if="!data.isNew" cols="12" sm="4">
                    <v-divider />
                    <v-subheader>Picked</v-subheader>

                    <v-slide-x-transition hide-on-leave>
                        <BT-Btn
                            v-if="item.pickedOn == null"
                            class="primary ma-1"
                            icon
                            label="Pick"
                            leftIcon="mdi-pickaxe"
                            :to="{ name: 'picking-hub', params: { consignmentID: item.id }}" />
                        <v-card v-else flat class="success ma-1">
                            <v-card-text>
                                <v-row no-gutters>
                                    <v-icon left small>mdi-check</v-icon>
                                    <v-col>
                                        <BT-Entity
                                            inline
                                            :itemValue="item.pickedByUserID"
                                            navigation="public-users"
                                            prefix="Picked by "
                                            single
                                            textFilter="toUserLine"
                                            useLocalCache />
                                        <div>
                                            <span>on {{ item.pickedOn | toShortDateAndTime }}</span>
                                        </div>
                                    </v-col>
                                </v-row>
                            </v-card-text>
                        </v-card>
                    </v-slide-x-transition>
                </v-col>

                <v-col v-if="!data.isNew" cols="12" sm="4">
                    <v-divider />
                    <v-subheader>Packed</v-subheader>

                    <v-slide-x-transition hide-on-leave>
                        <BT-Btn
                            v-if="item.packedOn == null"
                            class="primary ma-1"
                            icon
                            label="Pack"
                            leftIcon="mdi-pickaxe"
                            :to="{ name: 'picking-hub', params: { consignmentID: item.id }}" />
                        <v-card v-else flat class="success ma-1">
                            <v-card-text>
                                <v-row no-gutters>
                                    <v-icon left small>mdi-check</v-icon>
                                    <v-col>
                                        <BT-Entity
                                            inline
                                            :itemValue="item.pickedByUserID"
                                            navigation="public-users"
                                            prefix="Packed by "
                                            single
                                            textFilter="toUserLine"
                                            useLocalCache />
                                        <div>
                                            <span>on {{ item.packedOn | toShortDateAndTime }}</span>
                                        </div>
                                    </v-col>
                                </v-row>
                            </v-card-text>
                        </v-card>
                    </v-slide-x-transition>
                </v-col>

                <v-col cols="12">
                    <v-divider v-if="!data.isNew" class="my-1" />
                </v-col>

                <v-col cols="12">
                    <BT-Btn-Row v-if="!data.isNew">
                        <template v-slot:right>
                            <BT-Btn
                                v-if="!item.isSellerResolved && (item.performedOn != null || !item.isDispatched && item.isFullyProvisioned && !item.sourceHasChanged && item.departureLocationID != null)"
                                @click="archive(item)"
                                leftIcon="mdi-archive-arrow-down-outline"
                                label="Archive"
                                title="Archive consignment" />

                            <BT-Btn
                                v-if="item.departureLocationID != null && item.performedOn == null && !item.isDispatched"
                                @click="postConsignmentFor(item)"
                                leftIcon="mdi-arrow-right-circle"
                                label="Consign"
                                title="Proceed to next step of consignment - a courier consignment" />

                            <BT-Btn
                                v-if="item.departureLocationID != null && item.isDispatched && item.performedOn == null"
                                :bladesData="data"
                                bladeName="customer-consignment"
                                leftIcon="mdi-clipboard-outline"
                                :id="item.id"
                                label="Courier Consignment" />
                        </template>
                    </BT-Btn-Row>
                </v-col>

                <v-col cols="12">
                    <div v-if="data.isNew">
                        <v-tabs v-model="panelV">
                            <v-tab>Sold</v-tab>
                            <v-tab>Acquired</v-tab>
                            <v-tab>Manufactured</v-tab>
                        </v-tabs>
                        <v-tabs-items v-model="panelV">
                            <v-tab-item>
                                <BT-List :items="item.consignmentOrderItems" :onFilter="f => f.filter(x => x.product.isSold)">
                                    <template v-slot:listItem="{ item }">
                                        <v-list-item dense>
                                            <BT-List-Item-Avatar
                                                v-model="item.productID"
                                                avatarType="product"
                                                :rounded="false" />
                                            <v-list-item-content>
                                                <v-list-item-title>{{ item.product.abbreviation }} - {{ item.product.productName }}</v-list-item-title>
                                            </v-list-item-content>
                                            <v-list-item-action>
                                                <BT-Number-Edit v-model="item.quantity" />
                                            </v-list-item-action>
                                        </v-list-item>
                                    </template>
                                </BT-List>
                            </v-tab-item>
                            <v-tab-item>
                                <BT-List :items="item.consignmentOrderItems" :onFilter="f => f.filter(x => x.product.isAcquired)">
                                    <template v-slot:listItem="{ item }">
                                        <v-list-item dense>
                                            <BT-List-Item-Avatar
                                                v-model="item.productID"
                                                avatarType="product"
                                                :rounded="false" />
                                            <v-list-item-content>
                                                <v-list-item-title>{{ item.product.abbreviation }} - {{ item.product.productName }}</v-list-item-title>
                                            </v-list-item-content>
                                            <v-list-item-action>
                                                <BT-Number-Edit v-model="item.quantity" />
                                            </v-list-item-action>
                                        </v-list-item>
                                    </template>
                                </BT-List>
                            </v-tab-item>
                            <v-tab-item>
                                <BT-List :items="item.consignmentOrderItems" :onFilter="f => f.filter(x => x.product.isManufactured)">
                                    <template v-slot:listItem="{ item }">
                                        <v-list-item dense>
                                            <BT-List-Item-Avatar
                                                v-model="item.productID"
                                                avatarType="product"
                                                :rounded="false" />
                                            <v-list-item-content>
                                                <v-list-item-title>{{ item.product.abbreviation }} - {{ item.product.productName }}</v-list-item-title>
                                            </v-list-item-content>
                                            <v-list-item-action>
                                                <BT-Number-Edit v-model="item.quantity" />
                                            </v-list-item-action>
                                        </v-list-item>
                                    </template>
                                </BT-List>
                            </v-tab-item>
                        </v-tabs-items>
                    </div>
                    <BT-List-Endless v-else
                        :canSearch="false"
                        :items="item.consignmentOrderItems">
                        <template v-slot:toolbar-below>
                            <v-list-item>
                                <v-list-item-title>Ordered Items</v-list-item-title>
                                <v-list-item-action>
                                    <BT-Btn
                                        v-if="item.isTransfer"
                                        @click="toggleProducts(item)"
                                        class="primary"
                                        inline
                                        :label="showAllProducts ? 'Filter' : 'Show All'" />
                                </v-list-item-action>
                            </v-list-item>
                        </template>
                        <template v-slot:listItem="{ item }">
                            <v-list-group v-if="isLengthyArray(item.entryItems)">
                                <template v-slot:activator>
                                    <v-list-item-avatar>
                                        <v-icon v-if="item.quantity > 0 && item.quantityAssigned == item.quantity" class="success--text">mdi-check</v-icon>
                                        <v-icon v-else-if="item.entryItems.length > 0" class="warning--text">mdi-check</v-icon>
                                        <v-icon v-else class="error--text">mdi-close</v-icon>
                                    </v-list-item-avatar>
                                    <v-list-item-content>
                                        <v-list-item-title>{{ item.product.abbreviation }}</v-list-item-title>
                                        <v-list-item-subtitle>{{ item.product.productName }}</v-list-item-subtitle>
                                    </v-list-item-content>
                                    <v-list-item-action>
                                        <BT-Increment-Quantity-Edit
                                            v-model="item.quantity"
                                            :goal="item.quantity"
                                            inline
                                            :isEditing="data.isNew || (data.isEditing && item.isTransfer)"
                                            :productID="item.productID" />
                                    </v-list-item-action>
                                </template>
                                <BT-List :items="item.entryItems">
                                    <template v-slot="{ item }">
                                    <v-list-item-content>
                                        <v-list-item-subtitle v-if="item.batch != null">{{ item.batch.batchcode }}</v-list-item-subtitle>
                                        <v-list-item-subtitle v-if="item.batch != null">DOM: {{ item.batch.dom | toShortDate }} | EXP: {{ item.batch.exp | toShortDate }}</v-list-item-subtitle>
                                    </v-list-item-content>
                                    <v-list-item-action>
                                        <BT-Increment-Quantity-Edit
                                        v-model="item.quantity"
                                        :goal="item.quantity"
                                        inline
                                        :isEditing="false"
                                        :productID="item.productID" />
                                    </v-list-item-action>
                                    </template>
                                </BT-List>
                            </v-list-group>
                            <v-list-item v-else>
                                <v-list-item-avatar>    
                                    <v-icon v-if="item.quantity > 0 && item.quantityAssigned == item.quantity" class="success--text">mdi-check</v-icon>
                                    <v-icon v-else-if="item.entryItems.length > 0" class="warning--text">mdi-check</v-icon>
                                    <v-icon v-else class="error--text">mdi-close</v-icon>
                                </v-list-item-avatar>
                                <v-list-item-content>
                                    <v-list-item-title>{{ item.product.abbreviation }}</v-list-item-title>
                                    <v-list-item-subtitle>{{ item.product.productName }}</v-list-item-subtitle>
                                </v-list-item-content>
                                <v-list-item-action>
                                    <BT-Increment-Quantity-Edit
                                        v-model="item.quantity"
                                        :goal="item.quantity"
                                        inline
                                        :isEditing="data.isNew || (data.isEditing && item.isTransfer)"
                                        :productID="item.productID" />
                                </v-list-item-action>
                            </v-list-item>
                        </template>
                    </BT-List-Endless>
                </v-col>
            </v-row>
            
            <BT-Snack v-model="msg" />

            <v-overlay 
                :value="!data.isEditing && !data.isNew && item.sourceHasChanged"
                absolute
                opacity="0.98">
                
                <v-container>
                    <v-list v-if="customerOrder != null" class="transparent">
                        <v-slide-x-transition>
                            <v-list-item v-if="customerOrder.isBuyerCourier != item.isBuyerCourier">
                                <v-list-item-content>
                                    <v-list-item-subtitle v-if="customerOrder.isBuyerCourier">Customer Is Requesting To Organize Courier</v-list-item-subtitle>
                                    <v-list-item-subtitle v-else>Customer Is Requesting Supplier To Organize Courier</v-list-item-subtitle>
                                </v-list-item-content>
                            </v-list-item>
                        </v-slide-x-transition>
                        
                        <v-divider v-if="customerOrder.isBuyerCourier != item.isBuyerCourier" class="my-3" />
                        
                        <v-slide-x-transition>
                            <v-list-item v-if="customerOrder.dueDate != item.dueDate">
                                <v-list-item-content>
                                    <v-list-item-subtitle>New Due Date</v-list-item-subtitle>
                                    <v-list-item-title>{{ customerOrder.dueDate | toShortDate }}</v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>
                        </v-slide-x-transition>
                        
                        <v-divider v-if="customerOrder.dueDate != item.dueDate" class="my-3" />
                        
                        <v-slide-x-transition>
                            <v-list-item v-if="customerOrder.customerOrderNumber != item.consignmentNumber">
                                <v-list-item-content>
                                    <v-list-item-subtitle>New Consignment Number</v-list-item-subtitle>
                                    <v-list-item-title>#{{ customerOrder.customerOrderNumber }}</v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>
                        </v-slide-x-transition>

                        <v-divider v-if="customerOrder.consignmentNumber != item.consignmentNumber" class="my-3" />

                        <v-slide-x-transition>
                            <v-list-item v-if="customerOrder.locationID != item.destinationLocationID">
                                <v-list-item-content>
                                    <v-list-item-subtitle>New Destination</v-list-item-subtitle>
                                    <v-list-item-title>{{ customerOrder.location | toLocationLine }}</v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>
                        </v-slide-x-transition>

                        <v-divider v-if="customerOrder.locationID != item.destinationLocationID" class="my-3" />

                        <v-slide-x-transition>
                            <v-list-item>
                                <v-list-item-content>
                                    <v-list-item-subtitle>*Consignment Stock Entries Need Adjustment</v-list-item-subtitle>
                                </v-list-item-content>
                            </v-list-item>
                        </v-slide-x-transition>

                        <v-btn
                            class="primary"
                            small
                            block
                            @click="saveChanges(item)">Acknowledge And Save</v-btn>

                    </v-list>

                </v-container>

            </v-overlay>

        </template>
    </BT-Blade-Item>
</template>

<script>
import { firstBy } from 'thenby';

export default {
    name: 'Stock-Consignment-Blade',
    components: {
        BTIncrementQuantityEdit: () => import('~components/BT-Increment-Quantity-Edit.vue'),
        BTMenu: () => import('~components/BT-Menu.vue'),
        BTSelectListBox: () => import('~components/BT-Select-List-Box.vue')
    },
    data: function() {
        return {
            changeToggle: false,
            customerOrder: null,
            isExternalTransfer: null,
            loadingMsg: null,
            msg: null,
            panelV: 0,
            refreshToggle: false,
            saveToggle: false,
            showAllProducts: false
        }
    },
    props: {
        bladesData: {
            type: Object,
            default: null
        },
    },
    methods: {
        async archive(item) {
            await this.$BlitzIt.store.patch('stock-consignments', { id: item.id, rowVersion: item.rowVersion, isArchiveRequest: true });
            this.$BlitzIt.store.deleteLocal('stock-consignments', item);
            this.$router.push({ name: 'stock-consignments', params: { refresh: true } });
        },
        async cancelConsignment(consignment) {
            try {
                this.loadingMsg = 'Cancelling Departure Location';
                await this.$BlitzIt.store.patch('stock-consignments', { id: consignment.id, rowVersion: consignment.rowVersion, departureLocationID: null })
                consignment.departureLocationID = null;
                consignment.departureLocation = null;
                this.refreshToggle = !this.refreshToggle;
            }
            finally {
                this.loadingMsg = null;
            }
        },
        async forceResolve(item) {
            this.loadingMsg = 'Resolving';
            try {
                if (await this.$confirm('Are you sure?')) {
                    await this.$BlitzIt.store.patch('stock-consignment-fulfilling', { id: item.id, performedOn: this.$BlitzIt.auth.createUTC() });
                    this.$router.push({ name: 'stock-consignments' });
                }
            }
            catch (err) {
                this.msg = this.extractErrorDescription(err);
            }
            finally {
                this.loadingMsg = null;
            }
        },
        async selectSupplyLocation(item) {
            try {
                this.loadingMsg = 'Supplying';
                var locations = await this.$BlitzIt.store.getAll('locations');
                var suggestionRes = await this.$BlitzIt.api.getAll('supply-location-suggestions', { ID: item.destinationLocationID, dueDate: item.dueDate });
                var suggestions = suggestionRes.data.data;
                var lCopy = locations.map(x => { return Object.assign({}, { ...x, isSuggestion: false }); });

                suggestions.forEach(sug => {
                    var existing = lCopy.find(l => l.id == sug.id);
                    if (existing != null) {
                        existing.isSuggestion = true;
                    }
                })
                
                var s = await this.$selectItemFrom({
                    items: lCopy,
                    label: 'Supply Locations',
                    itemText: 'locationName',
                    onFilter: (list) => { return list.filter(l => l.id != item.destinationLocationID); },
                    subTextFunction: (item) => { return item.isSuggestion ? '*Suggested' : 'Option'; }
                });

                if (s != null) {
                    item.departureLocation = s;
                    item.departureLocationID = s.id;
                    item.consignmentEntryItems = [];
                    await this.$BlitzIt.store.patch('stock-consignments', item);
                    this.$BlitzIt.store.deleteLocal('stock-consignments', item.id);
                    this.refreshToggle = !this.refreshToggle;
                }
            }
            catch (err) {
                this.msg = this.extractErrorDescription(err);
            }
            finally {
                this.loadingMsg = null;
            }
        },
        async updateWeightsAndPackageCount(item) {
            this.loadingMsg = 'Updating Weights';
            try {
                //item.updateDispatch = true
                await this.$BlitzIt.store.patch('stock-consignments', item);
                if (this.bladesData == null) {
                    this.$router.push({ name: 'stock-consignments' });
                }
            }
            catch (err) {
                this.msg = this.extractErrorDescription(err);
            }
            finally {
                this.loadingMsg = null;
            }
        },
        newConsignment() {
            return {
                departureLocationID: null,
                destinationLocationID: null,
                dueDate: null,
                consignmentOrderItems: [],
                consignmentEntryItems: []
            }
        },
        async pullConsignment(consignment, refresh, bladeData) { //, bladeData) {
            if (bladeData.data?.id == 'new')  {
                //load products
                this.showAllProducts = false;
                await this.toggleProducts(consignment);
            }
            else {
                this.groupConsignmentItems(consignment);
            }
            
            // if (consignment.isTransfer) { //consignment.buyerID == consignment.sellerID) {
            //     //fill with all products
                
            // }
            // else 
            if (consignment.sourceHasChanged === true) {
                this.customerOrder = await this.$BlitzIt.store.get('customer-orders', consignment.id, null, refresh);
            }

            consignment.consignmentEntryItems.sort(firstBy(x => x.product.sortNumber));
            consignment.consignmentOrderItems.sort(firstBy(x => x.product.sortNumber));

            return consignment;
        },
        async postConsignmentFor(po) {
            try {
                this.loadingMsg = 'Courier Consigning';
                var data = { ...po, isDispatchRequest: true };
                var res = await this.$BlitzIt.store.patch('stock-consignments', data);
                po.isDispatched = true;
                this.changeToggle = !this.changeToggle;

                if (this.bladesData != null) {
                    this.bladesData.openBlade({ bladeName: 'customer-consignment', data: { id: res.id }});
                }
            }
            finally {
                this.loadingMsg = null;
            }
        },
        groupConsignmentItems(consignment) {
            var rList = [];

            consignment.consignmentOrderItems.forEach(oItem => {
                rList.push({
                    ...oItem,
                    quantityAssigned: 0,
                    entryItems: []
                });
            })

            consignment.consignmentEntryItems.forEach(eItem => {
                var existing = rList.find(z => z.productID == eItem.productID);
                if (existing == null) {
                    existing = {
                        product: eItem.product,
                        productID: eItem.productID,
                        quantity: 0,
                        quantityAssigned: 0,
                        entryItems: []
                    };

                    rList.push(existing);
                }

                existing.quantityAssigned += eItem.quantity;
                existing.entryItems.push(eItem);
            })

            rList.forEach(rItem => {
                rItem.entryItems.sort(firstBy(z => z.batch == null ? 0 : z.batch.dom));
            });

            rList.sort(firstBy(x => x.product.sortNumber));

            consignment.consignmentOrderItems = rList;
        },
        saveChanges(consignment) {
            if (this.customerOrder != null) {
                consignment.destinationLocationID = this.customerOrder.locationID;
                consignment.destinationLocation = this.customerOrder.location;
                consignment.isBuyerCourier = this.customerOrder.isBuyerCourier;
                consignment.consignmentNumber = this.customerOrder.customerOrderNumber;
                consignment.dueDate = this.customerOrder.dueDate;
                consignment.sourceHasChanged = false;
                this.saveToggle = !this.saveToggle;
            }
        },
        async toggleProducts(consignment) {
            this.showAllProducts = !this.showAllProducts;
            this.loadingMsg = 'Getting products';

            if (this.showAllProducts) {
                var products = await this.$BlitzIt.store.getAll('products', { properties: 'ID,Abbreviation,ProductName,SortNumber,IsSold,IsAcquired,IsManufactured' });
                products.forEach(prod => {
                    var eItem = consignment.consignmentOrderItems.find(x => x.productID == prod.id);
                    if (eItem == null) {
                        eItem = {
                            id: null,
                            productID: prod.id,
                            product: prod,
                            quantity: 0,
                            quantityAssigned: 0,
                            entryItems: []
                        }

                        consignment.consignmentOrderItems.push(eItem);
                    }
                });
            }
            else {
                consignment.consignmentOrderItems = consignment.consignmentOrderItems.filter(z => z.id != null || z.quantity != 0);
            }

            this.groupConsignmentItems(consignment);
            this.loadingMsg = null;
        },
        updateBlade(d) {
            if (d != null && d.data.id === 'new') {
                this.isExternalTransfer = null;
            }
            else {
                console.log('updating blade');
                console.log(d);
                this.isExternalTransfer = false;
            }
        }
    }
}
</script>